<template>
    <div>
        <page-header :pageTitle="title" @second-action="$router.push({name: 'indexConfigSimulator'})"
                     :btn-new="getTitleButton()" :icon-btn-new="getIcon()" second-btn="ACTIONS.BACK"
                     @new-type="action === 'view' ? toEdit() : save()" second-icon-btn-new="icon-arrow_back"/>
        <div class="card" v-if="installmentConfigs && !installmentConfigs?.length">
            <div class="card-header"/>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('CONFIG_CLICK_SIGN.NONE_FOUND') }}</h5>
                </div>
            </div>
        </div>
        <form v-else>
            <div class="row gutters">
                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <div class="row gutters">
                        <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-7">
                            <div class="form-group">
                                <label for="name" class="required">{{ t('CONFIG_SIMULATOR.NAME') }}: </label>
                                <input type="text" id="name" v-model="simulatorConfig.name" name="name"
                                       class="form-control" :disabled="disableInputs"
                                       placeholder="Digite o nome">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-5">
                            <div class="form-group">
                                <label for="formula" class="required">{{ t('CONFIG_SIMULATOR.FORMULA') }}:</label>
                                <select2 :settings="{width: '100%', minimumResultsForSearch: Infinity}"
                                         :options="formulas" :disabled="true"
                                         id="formula" name="formula" v-model="simulatorConfig.formula"/>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="advance_terms"
                                       name="advance_terms" :disabled="disableInputs"
                                       v-model="simulatorConfig.advance_terms">
                                <label class="custom-control-label pointer" for="advance_terms"
                                       title="Se habilitado, ao escolher uma Data de Vencimento, o sistema irá calcular os juros desde o dia de lançamento até a data escolhida para a primeira parcela">{{
                                        t('CONFIG_SIMULATOR.ADVANCE_TERMS')
                                    }}
                                    <i class="ml-1 icon-info-with-circle"/>
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input"
                                       :disabled="disableIntermediateReverse || disableInputs"
                                       id="intermediate_reverse" v-model="simulatorConfig.intermediate_reverse"
                                       name="intermediate_reverse">
                                <label class="custom-control-label pointer" for="intermediate_reverse"
                                       title="Aplica o valor dos juros das parcelas intermediárias no saldo devedor, deixando assim o valor das parcelas fixo como se fosse sem juros, porém os juros estarão adicionados no saldo devedor final (Apenas para parcelas intermediárias e usando fórmula PRICE).">
                                    <template v-if="disableIntermediateReverse">
                                        <s>{{ t('CONFIG_SIMULATOR.INTERMEDIATE_REVERSE') }}</s>
                                    </template>
                                    <template v-else>
                                        {{ t('CONFIG_SIMULATOR.INTERMEDIATE_REVERSE') }}
                                    </template>
                                    <i class="ml-1 icon-info-with-circle"/>
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="hide_formula_name"
                                       :disabled="disableInputs"
                                       v-model="simulatorConfig.hide_formula_name" name="hide_formula_name">
                                <label class="custom-control-label pointer" for="hide_formula_name"
                                       title="Oculta o nome da formula selecionada no títudo do simulador.">
                                    {{ t('CONFIG_SIMULATOR.HIDE_FORMULA_NAME') }}
                                    <i class="ml-1 icon-info-with-circle"/>
                                </label>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="allow_generate_sale"
                                       :disabled="disableInputs"
                                       v-model="simulatorConfig.allow_generate_sale" name="allow_generate_sale">
                                <label class="custom-control-label pointer" for="allow_generate_sale"
                                       title="Semelhante à proposta, permite criar uma venda se a simulação for feita em um lote reservado, com os dados necessários preenchidos.">
                                    {{ t('CONFIG_SIMULATOR.ALLOW_GENERATE_SALE_ON_SIMULATOR') }}
                                    <i class="ml-1 icon-info-with-circle"/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <label for="description">{{ t('CONFIG_SIMULATOR.DESCRIPTION') }}:</label>
                        <textarea class="form-control" :disabled="disableInputs" rows="4" id="decription"
                                  v-model="simulatorConfig.description"/>
                    </div>
                </div>
            </div>
            <div class="row gutters">
                <div v-for="block in installmentConfigs" :key="block.position"
                     class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="accordion toggle-icons" id="toggleIcons">
                        <div class="accordion-container">
                            <div class="accordion-header text-center border"
                                 :style="{backgroundColor: block.background_color, border: block.background_color}"
                                 :id="`toggleIcons${block.position}`">
                                <a data-toggle="collapse" :data-target="`#toggleIconsCollapse${block.position}`"
                                   :aria-controls="`toggleIconsCollapse${block.position}`" style="color: #000">
                                    {{ block.label }}
                                </a>
                            </div>
                            <div :id="`toggleIconsCollapse${block.position}`" class="collapse"
                                 :data-parent="`#toggleIcons${block.position}`"
                                 :aria-labelledby="`toggleIcons${block.position}`">
                                <div class="accordion-body">
                                    <div class="row gutters">
                                        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-5 col-12">
                                            <div class="form-group">
                                                <label for="input_label">{{ t('CONFIG_SIMULATOR.LABEL') }}:</label>
                                                <input id="input_label" class="form-control" :disabled="disableInputs"
                                                       v-model="block.label" name="input_label">
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-5 col-md-7 col-sm-7 col-12">
                                            <div class="form-group">
                                                <label
                                                    for="default_payment_form_id">{{
                                                        t('CONFIG_SIMULATOR.PAYMENT_DEFAULT_FORM')
                                                    }}:</label>
                                                <select2 placeholder="Forma de pagamento" :settings="{width: '100%', allowClear: true}"
                                                         :options="paymentForms" :disabled="disableInputs"
                                                         v-model="block.default_payment_form_id"/>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-4 col-12"
                                             :class="posHaveEntrance.includes(block.position) ? 'col-md-6 col-sm-2' :
                                              'col-md-4 col-sm-4'">
                                            <div class="form-group">
                                                <label for="interest">{{ t('CONFIG_SIMULATOR.PERIODICITY') }}:</label>
                                                <select2 placeholder="Periodicidade" :settings="{width: '100%'}"
                                                         :options="periodicityOptions" :disabled="disableInputs"
                                                         v-model="block.periodicity"/>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-md-6 col-sm-5 col-12"
                                             :class="posHaveEntrance.includes(block.position) ? 'col-lg-4' :
                                         'col-lg-6'">
                                            <div class="form-group">
                                                <label for="initial_interest" class="pointer"
                                                       title="Começa a aplicar juros a partir desta parcela. Válido apenas para fórmula PRICE.">
                                                    {{ t('CONFIG_SIMULATOR.INTEREST_FROM') }} <i
                                                    class="ml-1 icon-info-with-circle"/>
                                                </label>
                                                <input class="form-control" type="number" min="1" id="initial_interest"
                                                       :disabled="disableInitialInterestInstallment || disableInputs"
                                                       v-model="block.initial_interest_installment"/>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-12"
                                             :class="posHaveEntrance.includes(block.position) ? 'col-xl-1 col-lg-4 col-sm-1' :
                                              'col-xl-3 col-lg-6 col-sm-3'">
                                            <div class="form-group">
                                                <label for="background_color">{{ t('CONFIG_SIMULATOR.COLOR') }}:</label>
                                                <input type="color" id="background_color" class="form-control"
                                                       :disabled="disableInputs"
                                                       v-model="block.background_color" name="background_color">
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-md-5 col-sm-4 col-12"
                                             :class="posHaveEntrance.includes(block.position) ? 'col-lg-4' :
                                              'col-lg-5'">
                                            <div class="form-group">
                                                <label for="interest">{{
                                                        t('CONFIG_SIMULATOR.MIN_QUANTITY_INSTALLMENT')
                                                    }}:</label>
                                                <input class="form-control" type="number" min="1"
                                                       :disabled="disableInputs"
                                                       v-model="block.min_quantity_installment"/>
                                            </div>
                                        </div>
                                        <div class="col-xl-3 col-md-5 col-sm-4 col-12"
                                             :class="posHaveEntrance.includes(block.position) ? 'col-lg-4' :
                                              'col-lg-5'">
                                            <div class="form-group">
                                                <label for="interest" class="pointer"
                                                       title="Valores menores que 1 serão tratados como nulo.">{{
                                                        t('CONFIG_SIMULATOR.MAX_QUANTITY_INSTALLMENT')
                                                    }}: <i
                                                        class="ml-1 icon-info-with-circle"/>
                                                </label>
                                                <input class="form-control" type="number" min="-1"
                                                       :disabled="disableInputs"
                                                       v-model="block.max_quantity_installment"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-12"
                                             :class="posHaveEntrance.includes(block.position) ? 'col-xl-2 col-md-6 col-sm-3'
                                              : 'col-xl-3 col-md-2 col-sm-4'">
                                            <div class="form-group">
                                                <label for="interest">{{ t('CONFIG_SIMULATOR.INTEREST') }}:</label>
                                                <input type="text" id="interest" class="form-control" name="interest"
                                                       :disabled="disableInputs"
                                                       v-model.lazy="block.interest" v-money3="configPercentageValue">
                                            </div>
                                        </div>
                                        <div v-if="block.position === position.entrance"
                                             class="col-xl-3 col-lg-6 col-md-6 col-sm-5 col-12">
                                            <div class="form-group">
                                                <label for="entrance_value">{{
                                                        `Valor Mínimo de ${block.label}`
                                                    }}:</label>
                                                <input type="text" class="form-control" id="entrance_value"
                                                       name="entrance_value" :disabled="disableInputs"
                                                       v-model.lazy="simulatorConfig.entrance_value"
                                                       v-money3="entranceFixe ? configCurrencyValue : configPercentageValue"
                                                >
                                            </div>
                                        </div>
                                        <div v-if="block.position === position.brokerage"
                                             class="col-xl-3 col-lg-6 col-md-6 col-sm-5 col-12">
                                            <div class="form-group">
                                                <label for="brokerage_value">{{
                                                        `Valor de ${block.label}`
                                                    }}:</label>
                                                <input type="text" class="form-control" id="brokerage_value"
                                                       name="brokerage_value"
                                                       v-model.lazy="simulatorConfig.brokerage_value"
                                                       v-money3="brokerageFixe ? configCurrencyValue : configPercentageValue"
                                                >
                                            </div>
                                        </div>
                                        <div class="row gutters w-100">
                                            <div v-if="block.position === position.entrance"
                                                 class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="entrance_fixed" name="entrance_fixed"
                                                           v-model="simulatorConfig.entrance_fixed"
                                                           :disabled="disableInputs"
                                                           @click="changeTypeValue('entrance_value')">
                                                    <label class="custom-control-label pointer"
                                                           for="entrance_fixed">{{
                                                            `${block.label} em Valor Fixo`
                                                        }}</label>
                                                </div>
                                            </div>
                                            <div v-if="block.position === position.brokerage"
                                                 class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input"
                                                           id="brokerage_fixed" name="brokerage_fixed"
                                                           v-model="simulatorConfig.brokerage_fixed"
                                                           :disabled="disableInputs"
                                                           @click="changeTypeValue('brokerage_value')"
                                                    >
                                                    <label class="custom-control-label pointer"
                                                           for="brokerage_fixed">{{
                                                            `${block.label} em Valor Fixo`
                                                        }}</label>
                                                </div>
                                            </div>
                                            <div v-if="block.position !== position.regular"
                                                 :class="posHaveEntrance.includes(block.position) ?
                                                 'col-xl-4 col-lg-4' :
                                                 'col-xl-4 col-lg-4'"
                                                 class="col-sm-6 col-md-6 col-12">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input"
                                                           :id="`reduce_from_regular_${block.position}`"
                                                           :name="`reduce_from_regular_${block.position}`"
                                                           :disabled="disableInputs"
                                                           v-model="block.reduce_from_regular">
                                                    <label class="custom-control-label pointer"
                                                           :for="`reduce_from_regular_${block.position}`">
                                                        {{ t('CONFIG_SIMULATOR.REDUCE_FROM_REGULAR') }}</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-12"
                                                 :class="posHaveEntrance.includes(block.position) ?
                                            'col-xl-4 col-lg-6' :
                                             'col-xl-4 col-lg-4'">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input"
                                                           :id="`show_interest_value_total${block.position}`"
                                                           :name="`show_interest_value_total${block.position}`"
                                                           :disabled="disableInputs"
                                                           v-model="block.show_interest_value_total">
                                                    <label class="custom-control-label pointer"
                                                           :for="`show_interest_value_total${block.position}`">
                                                        {{ t('CONFIG_SIMULATOR.SHOW_TOTAL_INTEREST_VALUE') }}</label>
                                                </div>
                                            </div>
                                            <div class="col-xl-4 col-md-6 col-sm-6 col-12"
                                                 v-if="block.position !== position.regular"
                                                 :class="posHaveEntrance.includes(block.position) ? 'col-lg-6' :'col-lg-4'">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input"
                                                           :id="`allow_edit_after_calc_${block.position}`"
                                                           :name="`allow_edit_after_calc_${block.position}`"
                                                           :disabled="disableInputs"
                                                           v-model="block.allow_edit_after_calc">
                                                    <label class="custom-control-label pointer"
                                                           :for="`allow_edit_after_calc_${block.position}`">
                                                        {{ t('CONFIG_SIMULATOR.ALLOW_EDIT_AFTER_CALC') }}</label>
                                                </div>
                                            </div>
                                            <div v-if="block.position !== position.regular"
                                                 :class="posHaveEntrance.includes(block.position) ?
                                                 'col-xl-4 col-lg-6 col-md-6 col-sm-6' :
                                                  'col-xl-4 col-lg-4 col-md-6 col-sm-4'"
                                                 class="col-12">
                                                <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input"
                                                           :id="`show${block.position}`" :disabled="disableInputs"
                                                           name="show" v-model="block.show">
                                                    <label class="custom-control-label pointer"
                                                           :for="`show${block.position}`">
                                                        {{ t('CONFIG_SIMULATOR.USE_INSTALLMENT_TYPE') }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center mt-3 w-100">
                                            <label>{{ t('CONFIG_SIMULATOR.CHOOSE_DAYS_TO_HABILITY_PAYMENTS') }}</label>
                                            <div class="d-flex flex-wrap justify-content-center align-items-center">
                                                <div v-for="day in daysInMonth" :key="day">
                                                    <div
                                                        :title="disableInputs ? '' : block.not_allowed_pay_days.includes(day) ? 'Marcar' : 'Desmarcar'"
                                                        style="border-radius: 50%!important; border: 1px solid #0e5e4e"
                                                        :class="{'selected-day': !block.not_allowed_pay_days.includes(day), 'cursor-def': disableInputs, 'pointer': !disableInputs}"
                                                        class="p-1"
                                                        :style="{backgroundColor: disableInputs ? (!block.not_allowed_pay_days.includes(day) ? '#0e5e4eb3' : '#fff') : ''}"
                                                        @click.prevent.stop="disableInputs || toggleDay(block, day)">
                                                        {{ day }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import {useI18n} from 'vue-i18n';
import {Money3Directive} from 'v-money3';
import Enum from '@/enums/SimulatorEnum';
import {useToast} from 'vue-toastification';
import Select2 from 'vue3-select2-component';
import Validate from '@/components/validate.vue';
import paymentMethods from '@/services/paymentMethods';
import SimulatorConfig from '@/services/SimulatorConfig';
import PageHeader from '@/components/layouts/pageHeader.vue';

export default {
    name: 'createConfigSimulatorComponent',

    mixins: [Validate],

    directives: {
        money3: Money3Directive,
    },

    components: {
        Select2,
        PageHeader,
    },

    setup() {
        const {t} = useI18n();
        const toast = useToast();

        return {t, toast};
    },

    watch: {
        'simulatorConfig.formula'(formula) {
            if (formula !== Enum.defaultFormula) {
                this.simulatorConfig.intermediate_reverse = false;
                this.disableIntermediateReverse = true;

                this.disableInitialInterestInstallment = true;
                this.installmentConfigs.forEach(item => item.initial_interest_installment = 1);
            } else {
                this.disableIntermediateReverse = false;
                this.disableInitialInterestInstallment = false;
            }
        },
    },

    computed: {
        entranceFixe() {
            return this.simulatorConfig.entrance_fixed ?? false;
        },
        brokerageFixe() {
            return this.simulatorConfig.brokerage_fixed ?? false;
        },
    },

    data() {
        return {
            title: 'GENERAL.EMPTY',
            action: 'add',
            paymentForms: [],
            simulatorConfig: {},
            disableInputs: false,
            daysInMonth: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15',
                '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
            installmentConfigs: [],
            configEntranceInput: {},
            configBrokerageInput: {},
            asViewDefaultConfig: false,
            posHaveEntrance: [Enum.entrance, Enum.brokerage],
            formulas: Enum.formulas,
            disableIntermediateReverse: false,
            disableInitialInterestInstallment: false,
            position: {
                entrance: Enum.entrance,
                arras: Enum.arras,
                intermediate: Enum.intermediate,
                brokerage: Enum.brokerage,
                regular: Enum.regular,
            },
            configCurrencyValue: {
                suffix: '',
                decimal: ',',
                number: true,
                masked: true,
                thousands: '.',
                prefix: 'R$ ',
                disabled: false,
                max: 100000000000,
                minimumNumberOfCharacters: 1,
            },
            configPercentageValue: {
                max: 100,
                prefix: '',
                decimal: '.',
                number: true,
                thousands: '',
                masked: true,
                suffix: '%',
                precision: 4,
                disabled: false,
                minimumNumberOfCharacters: 0,
            },
            periodicityOptions: [
                {id: '1', text: 'Mensal'},
                {id: '3', text: 'Trimestral'},
                {id: '6', text: 'Semestral'},
                {id: '12', text: 'Anual'},
            ],
        };
    },

    mounted() {
        this.getPaymentMethods();
        this.setAction();
        (this.action === 'add') ? this.setDefaultData() : this.show();
    },

    methods: {
        async save() {
            if (this.checkForm()) {
                const body = {
                    name: this.simulatorConfig.name,
                    entrance_fixed: this.simulatorConfig.entrance_fixed,
                    brokerage_fixed: this.simulatorConfig.brokerage_fixed,
                    formula: this.simulatorConfig.formula,
                    description: this.simulatorConfig.description,
                    company_id: localStorage.getItem('companyId'),

                    advance_terms: this.simulatorConfig.advance_terms ?? false,
                    intermediate_reverse: this.simulatorConfig.intermediate_reverse ?? false,
                    hide_formula_name: this.simulatorConfig.hide_formula_name ?? false,
                    allow_generate_sale: this.simulatorConfig.allow_generate_sale ?? false,
                    installmentConfigs: [],
                };

                body.entrance_value = body.entrance_fixed ?
                    this.currentValueToFloat(this.simulatorConfig.entrance_value) :
                    this.percentValueToFloat(this.simulatorConfig.entrance_value);

                body.brokerage_value = body.brokerage_fixed ?
                    this.currentValueToFloat(this.simulatorConfig.brokerage_value) :
                    this.percentValueToFloat(this.simulatorConfig.brokerage_value);

                this.installmentConfigs.forEach(config => {
                    const interest = this.percentValueToFloat(config.interest);

                    body.installmentConfigs.push({
                        id: config?.id,
                        position: config.position,
                        label: config.label,
                        interest: interest > 0 ? (interest / 100) : interest,
                        show: config.show,
                        periodicity: config.periodicity,
                        background_color: config.background_color,
                        default_payment_form_id: config.default_payment_form_id,
                        reduce_from_regular: config.reduce_from_regular,
                        show_interest_value_total: config.show_interest_value_total,
                        min_quantity_installment: config.min_quantity_installment,
                        max_quantity_installment: config.max_quantity_installment,
                        initial_interest_installment: config.initial_interest_installment,
                        not_allowed_pay_days: config.not_allowed_pay_days,
                        allow_edit_after_calc: config.allow_edit_after_calc,
                    });
                });

                if (this.$route.params.id === '1') {
                    this.toast.warning(this.t('CONFIG_SIMULATOR.NOT_POSSIBLE_EDIT_DEFAULT_SIMULATOR_CONFIG'));
                    this.$router.push({name: 'indexConfigSimulator'});
                } else {
                    (this.action === 'edit') ? this.upsert(SimulatorConfig.update({
                            ...body,
                            id: this.$route.params.id,
                        }), 'atualizada') :
                        this.upsert(SimulatorConfig.create(body), 'adicionada');
                }
            }
        },

        changeTypeValue(model) {
            setTimeout(() => this.simulatorConfig[model] = 0, 20);
        },

        toEdit() {
            this.$router.replace({params: {action: 'editar'}});
            this.action = 'edit';
            this.title = 'CONFIG_SIMULATOR.EDIT_CONFIG';
            if ((this.$route.params.id === '1')) {
                this.toast.warning(this.t('CONFIG_SIMULATOR.NOT_POSSIBLE_EDIT_DEFAULT_SIMULATOR_CONFIG'));
                return this.$router.push({name: 'indexConfigSimulator'});
            }
            this.disableInputs = false;
        },

        getTitleButton() {
            if (this.asViewDefaultConfig) return '';
            if (this.action === 'view') return 'ACTIONS.EDIT';

            return 'GENERAL.SAVE';
        },

        getIcon() {
            if (this.asViewDefaultConfig) return '';
            if (this.action === 'view') return 'icon-mode_edit';

            return 'icon-save';
        },

        setAction() {
            switch (this.$route.params.action) {
                case 'adicionar':
                    this.action = 'add';
                    this.title = 'CONFIG_SIMULATOR.NEW_CONFIG';
                    break;
                case 'editar':
                    this.action = 'edit';
                    this.title = 'CONFIG_SIMULATOR.EDIT_CONFIG';
                    if ((this.$route.params.id === '1')) {
                        this.toast.warning(this.t('CONFIG_SIMULATOR.NOT_POSSIBLE_EDIT_DEFAULT_SIMULATOR_CONFIG'));
                        return this.$router.push({name: 'indexConfigSimulator'});
                    }
                    break;
                default:
                    this.disableInputs = true;
                    this.action = 'view';
                    this.asViewDefaultConfig = (this.$route.params.id === '1');
                    this.title = this.asViewDefaultConfig ? 'CONFIG_SIMULATOR.VIEW_CONFIG_DEFAULT' : 'CONFIG_SIMULATOR.VIEW_CONFIG';
            }
        },

        toggleDay(block, day) {
            if (block.not_allowed_pay_days.includes(day)) block.not_allowed_pay_days = block.not_allowed_pay_days.filter(d => d !== day);
            else block.not_allowed_pay_days.push(day);
        },

        async getPaymentMethods() {
            await paymentMethods.list().then(resp => this.paymentForms = resp.data).catch(err => this.errorMsg(err));
        },

        upsert(promise, word) {
            this.$store.commit('changeLoading', true);

            promise.then(() => {
                this.toast.success(`Configuração ${word} com sucesso!`);
                this.$router.push({name: 'indexConfigSimulator'});
            }).catch(err => this.errorMsg(err)).finally(() => this.$store.commit('changeLoading', false));
        },

        show() {
            this.$store.commit('changeLoading', true);

            SimulatorConfig.show(this.$route.params.id).then(resp => {
                this.installmentConfigs = resp.data.type_installment_configs.map(item => {
                    item.default_payment_form_id = item.default_payment_form_id ? item.default_payment_form_id.toString() : null;
                    item.interest = (item.interest * 1000000);
                    item.not_allowed_pay_days = item?.not_allowed_pay_days ?? [];
                    return item;
                });

                this.simulatorConfig = {
                    ...resp.data,
                    entrance_value: (resp.data.entrance_value ?? 0) * (resp.data.entrance_fixed ? 100 : 10000),
                    brokerage_value: (resp.data.brokerage_value ?? 0) * (resp.data.brokerage_fixed ? 100 : 10000),
                };

                this.configEntranceInput = this.simulatorConfig.entrance_fixed ? this.configCurrencyValue : this.configPercentageValue;
                this.configBrokerageInput = this.simulatorConfig.brokerage_fixed ? this.configCurrencyValue : this.configPercentageValue;
            }).catch(err => this.errorMsg(err)).finally(() => this.$store.commit('changeLoading', false));
        },

        setDefaultData() {
            this.configEntranceInput = this.configCurrencyValue;
            this.configBrokerageInput = this.configCurrencyValue;
            this.simulatorConfig.formula = Enum.defaultFormula;
            this.simulatorConfig.entrance_fixed = false;
            this.simulatorConfig.brokerage_fixed = false;
            this.simulatorConfig.hide_formula_name = false;
            this.simulatorConfig.allow_generate_sale = false;

            this.installmentConfigs = [
                {
                    position: Enum.entrance,
                    label: Enum.labelEntrance,
                    interest: 0,
                    show: true,
                    background_color: Enum.defaultColorEntrance,
                    min_quantity_installment: 1,
                    max_quantity_installment: null,
                    reduce_from_regular: true,
                    allow_edit_after_calc: false,
                    show_interest_value_total: true,
                    initial_interest_installment: 1,
                    periodicity: '1',
                    not_allowed_pay_days: [],
                },
                {
                    position: Enum.arras,
                    label: Enum.labelArras,
                    interest: 0,
                    show: true,
                    background_color: Enum.defaultColorArras,
                    min_quantity_installment: 1,
                    max_quantity_installment: null,
                    reduce_from_regular: true,
                    allow_edit_after_calc: false,
                    show_interest_value_total: true,
                    initial_interest_installment: 1,
                    periodicity: '1',
                    not_allowed_pay_days: [],
                },
                {
                    position: Enum.intermediate,
                    label: Enum.labelIntermediate,
                    interest: 0,
                    show: true,
                    background_color: Enum.defaultColorIntermediate,
                    min_quantity_installment: 1,
                    max_quantity_installment: null,
                    reduce_from_regular: true,
                    allow_edit_after_calc: false,
                    show_interest_value_total: true,
                    initial_interest_installment: 1,
                    periodicity: '1',
                    not_allowed_pay_days: [],
                },
                {
                    position: Enum.brokerage,
                    label: Enum.labelBrokerage,
                    interest: 0,
                    show: true,
                    background_color: Enum.defaultColorBrokerage,
                    min_quantity_installment: 1,
                    max_quantity_installment: null,
                    reduce_from_regular: false,
                    allow_edit_after_calc: false,
                    show_interest_value_total: true,
                    initial_interest_installment: 1,
                    periodicity: '1',
                    not_allowed_pay_days: [],
                },
                {
                    position: Enum.regular,
                    label: Enum.labelRegular,
                    interest: 0,
                    show: true,
                    background_color: Enum.defaultColorRegular,
                    min_quantity_installment: 1,
                    max_quantity_installment: null,
                    reduce_from_regular: true,
                    allow_edit_after_calc: false,
                    show_interest_value_total: true,
                    initial_interest_installment: 1,
                    periodicity: '1',
                    not_allowed_pay_days: [],
                },
            ];
        },

        currentValueToFloat(value) {
            const number = value.replace('R$', '').trim();

            if (number) {
                return parseFloat(number.replace(/\./g, '').replace(',', '.'));
            }

            return 0;
        },

        percentValueToFloat(percent) {
            const number = percent.replace('%', '').trim();

            if (number) {
                return parseFloat(number);
            }

            return 0;
        },

        checkForm() {
            let error = false;

            if (!this.simulatorConfig.name) {
                this.toast.error('É necessário informar uma nome pra configuração');
                error = true;
            }

            this.installmentConfigs.forEach(config => {
                if (!config.label) {
                    this.toast.error('É necessário informar uma label para todas as configurações');
                    error = true;
                }
            });

            return !error;
        },
    },
};

</script>

<style scoped>
.selected-day {
    background-color: #0e5e4e;
    color: #fff;
}

.cursor-def {
    cursor: default;
}
</style>