import {http} from './config';

export default {
    index: (filter = null, page = 1, perpage = null) => {
        if (perpage === 'selecione' || !perpage) perpage = null;
        if (filter) {
            return http.get(`api/v1/loteamentos?page=${page}&filter=${filter}`, {params: {perpage}});
        }
        return http.get(`api/v1/loteamentos?page=${page}`, {params: {perpage}});
    },

    show: id => http.get(`api/v1/loteamentos/${id}`),

    showOnlyBd: id => http.get(`api/v1/loteamentos/${id}`, {params: {'onlyBd': true}}),

    dashboard: companyId => http.get('api/v1/dashboard/loteamentos', {headers: {'company-id': companyId}}),

    create: allotment => http.post('api/v1/loteamentos', allotment),

    update: allotment => http.put('api/v1/loteamentos/' + allotment.id, allotment),

    delete: id => http.delete(`api/v1/loteamentos/${id}`),

    list: boolean => http.get('api/v1/loteamentos/listar', {params: {'lote-not-null': boolean}}),

    listForUsers: (boolean, onlyUser = false) => {
        if (onlyUser) {
            return http.get('api/v1/loteamentos/listar', {
                params: {
                    'for-user': boolean,
                    'only-user': onlyUser,
                },
            });
        }
        return http.get('api/v1/loteamentos/listar', {params: {'for-user': boolean}});
    },

    listByCompany: companyId => http.get('api/v1/loteamentos/listar', {params: {'companyId': companyId}}),

    updateIdBd: (id, data) => http.put(`api/v1/loteamentos/idBd/${id}`, data),

    listWithoutBoolean: () => http.get('api/v1/loteamentos/listar'),

    listForMenu: companyId => http.get('api/v1/loteamentos/listar', {headers: {'company-id': companyId}}),

    listWithAmountReserve: companyId => http.get('api/v1/loteamentos/listarComQuantidadeReserva', {headers: {'company-id': companyId}}),

    listCenterCoust: idBd => http.get('api/v1/centro-de-custo', {headers: {'id-bd': idBd}}),

    listCostCenterOfAllotment: allotmentId => http.get(`api/v1/loteamentos/centro-de-custos/${allotmentId}`),

    getLabelsInstallments: allotmentId => http.get(`api/v1/loteamentos/calculadora-de-financiamento/${allotmentId}`),
};
