<template class="bodyC">
   <div class="container" style="min-width: 100vw" >
      <loadingScreen :isLoading="isLoading"/>
      <form @submit.prevent="login" style="margin-top: 6rem">
         <div class="row justify-content-md-center">
            <div class="login-screen">
               <div class="login-box text-center">
                  <a href="#" class="login-logo">
                    <img style="max-width: 200px; margin:auto" src="@/assets/img/logo.png" alt="R2 Soft">
<!--                     <img style="max-width: 200px; margin:auto" src="@/assets/img/logo_com_gorro.png" alt="R2 Soft">-->
                  </a>
                  <h5><b>{{ t('GENERAL.WELCOME') }},</b><br>{{ t('GENERAL.MSG.PLEASE_LOGIN') }}</h5>
                  <div class="form-group">
                     <input data-cy="login_usuario_input" type="text" id="username" v-model="username" name="username"
                            class="form-control" @keyup="inputEmail" placeholder="Endereço de e-mail"/>
                     <div class="validation" v-if="emailInvalid">{{ t('GENERAL.MSG.REQUIRED_FIELD') }}</div>
                     <div class="validation" v-if="emailFormInvalid">{{ t('GENERAL.MSG.EMAIL_INVALID') }}</div>
                  </div>
                  <div class="form-group">
                     <input data-cy="login_password_input" type="password" id="password" v-model="password"
                            name="password" class="form-control" @keyup="inputPassword" placeholder="Senha"/>
                     <div class="validation" v-if="passwordInvalid">{{ t('GENERAL.MSG.REQUIRED_FIELD') }}</div>
                  </div>
                  <div class="actions mb-4 justify-content-end">
                     <div data-cy="login_password_recover" class="forgot-pwd">
                        <router-link style="margin-top: 6px;" to="/recuperar-senha">
                           {{ t('GENERAL.MSG.FORGET_PASSWORD') }}
                        </router-link>
                     </div>
                  </div>
                  <button data-cy="login_login_button" style="width: 100%;" type="submit" class="btn btn-primary">
                     {{ t('GENERAL.LOGIN') }}
                  </button>
                  <google-singup v-if="!toFinalUser"></google-singup>
                  <hr>
                  <div v-if="!toFinalUser" class="actions align-left">
                     <span class="additional-link">{{ t('GENERAL.NEW') }}? </span>
                     <router-link data-cy="login_creat_account_button" class="btn btn-dark" to="/singup">
                        {{ t('GENERAL.CREATE_ACCOUNT') }}
                     </router-link>
                  </div>
               </div>
            </div>
         </div>
      </form>
   </div>
</template>

<script>

import {mapGetters} from 'vuex';
import User from '@/services/user';
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import googleSingup from './googleSingupComponent.vue';
import LoadingScreen from '@/components/layouts/loadScreenComponent.vue';

export default {
   name: 'LoginComponent',
   mixins: [Validate],
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {
         t,
         toast
      }
   },

   components: {
      googleSingup,
      LoadingScreen,
   },

   created() {
      document.title = this.t('APP.TITLE.SYSTEM') + this.t('APP.TITLE.LOGIN');
      if (process.env.VUE_APP_HIDE_CREATE_USER === 'true') {
         this.toFinalUser = true;
      }
   },

   data() {
      return {
         user: null,
         resp: null,
         invalid: [],
         username: null,
         password: null,
         isLoading: false,
         toFinalUser: false,
         emailInvalid: false,
         passwordInvalid: false,
         emailFormInvalid: false,
      }
   },

   computed: {
      ...mapGetters['user']
   },

   methods: {
      login() {
         if (this.checkForm()) {
            this.isLoading = true;
            this.user = {
               scope: "",
               password: this.password,
               username: this.username,
               client_id: process.env.VUE_APP_CLIENT_ID,
               client_secret: process.env.VUE_APP_CLIENT_SECRET,
               grant_type: process.env.VUE_APP_GRANT_TYPE_PASSWORD,
            },
                User.login(this.user).then(resp => {

                   this.$store.dispatch('ActionDoLogin', resp.data);
                   this.$router.push({name: 'Home'});

                }).catch(error => {
                   this.isLoading = false;
                   if (error.response === undefined){
                      this.toast.error('Sistema temporariamente indisponível, por favor tente mais tarde!');
                   }else {
                      this.error(error);
                   }
                });
         }
      },

      checkForm() {
         this.startValidation();

         this.emailValidate();
         this.passwordValidade();

         if (this.invalid.length && this.invalid.length > 0) {
            return false;
         }
         return true;
      },

      startValidation() {
         this.invalid = [];
         this.emailInvalid = false,
             this.passwordInvalid = false
      },

      validEmail(email) {
         var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         return re.test(email);
      },

      emailValidate() {
         if (!this.username) {
            this.emailInvalid = true;
            this.invalid.push(this.emailInvalid);
            document.getElementById('username').classList.add('is-invalid');

         } else if (!this.validEmail(this.username)) {
            this.emailFormInvalid = true;
            this.invalid.push(this.emailFormInvalid);
            document.getElementById('username').classList.add('is-invalid');

         } else {
            document.getElementById('username').classList.add('is-valid');
         }
         if (this.username){
            this.username = this.username.trim();
            this.username = this.username.toLowerCase();
         }
      },

      passwordValidade() {
         if (!this.password) {
            this.passwordInvalid = true;
            this.invalid.push(this.passwordInvalid);
            document.getElementById('password').classList.add('is-invalid');

         } else {
            document.getElementById('password').classList.add('is-valid');
         }
         if (this.password){
            this.password = this.password.trim();
         }
      },

      inputEmail() {
         this.emailInvalid = false;
         this.emailFormInvalid = false;
         document.getElementById('username').classList.remove('is-valid');
         document.getElementById('username').classList.remove('is-invalid');
         this.emailValidate();
      },

      inputPassword() {
         this.passwordInvalid = false;
         document.getElementById('password').classList.remove('is-valid');
         document.getElementById('password').classList.remove('is-invalid');
         this.passwordValidade();
      },

      async handleClickSignIn() {
         this.isLoading = true;
         try {
            const googleUser = await this.$gAuth.signIn();

            if (!googleUser)
               return null;

            this.onSignInGoogle(googleUser.getAuthResponse())

         } catch (error) {
            this.error(error);
            return null;
         }
      },

      onSignInGoogle(googleUser) {
         User.google(googleUser).then(resp => {
            this.$store.dispatch('ActionDoLogin', resp.data);
            this.isLoading = false;
            this.$router.push({name: 'Home'});
         });
      },

      error(error) {
         this.errorMsg(error);
         this.isLoading = false;
         document.getElementById('username').classList.remove('is-valid');
         document.getElementById('username').classList.add('is-invalid');
         document.getElementById('password').classList.remove('is-valid');
         document.getElementById('password').classList.add('is-invalid');
      },
   }
}
</script>
<style lang="css">
.fade-enter-active,
.fade-leave-active {
   transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
   opacity: 0;
}

.fa-google {
   background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
   -webkit-background-clip: text;
   background-clip: text;
   color: transparent;
   -webkit-text-fill-color: transparent;
   font-size: 17px;
}
.bodyC {
   background: #212121;
   background-image: url('../../assets/img/s1.png'), url('../../assets/img/s2.png'), url('../../assets/img/s3.png');
   background: #212121;
   background-image: url('../../assets/img/s1.png'), url('../../assets/img/s2.png'), url('../../assets/img/s3.png');
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   -webkit-animation: snow 10s linear infinite;
   -moz-animation: snow 10s linear infinite;
   -ms-animation: snow 10s linear infinite;
}
@keyframes snow {
   0% {background-position: 0px 0px, 0px 0px, 0px 0px;}
   50% {background-position: 500px 500px, 100px 200px, -100px 150px;}
   100% {background-position: 500px 1000px, 200px 400px, -100px 300px;}
}
@-moz-keyframes snow {
   0% {background-position: 0px 0px, 0px 0px, 0px 0px;}
   50% {background-position: 500px 500px, 100px 200px, -100px 150px;}
   100% {background-position: 400px 1000px, 200px 400px, 100px 300px;}
}
@-webkit-keyframes snow {
   0% {background-position: 0px 0px, 0px 0px, 0px 0px;}
   50% {background-position: 500px 500px, 100px 200px, -100px 150px;}
   100% {background-position: 500px 1000px, 200px 400px, -100px 300px;}
}
@-ms-keyframes snow {
   0% {background-position: 0px 0px, 0px 0px, 0px 0px;}
   50% {background-position: 500px 500px, 100px 200px, -100px 150px;}
   100% {background-position: 500px 1000px, 200px 400px, -100px 300px;}
}
</style>