<template>
    <div>
        <loadingScreen :isLoading="isLoading"/>
        <div class="page-wrapper" id="wrapper">

            <nav id="sidebar" class="sidebar-wrapper">
                <div class="sidebar-brand">
                    <a class="">
                        <router-link to="/" @click="isCompany = false">
                            <img style="max-width: 250px; margin:auto; width: 176px; margin-top: 5px"
                                 alt="R2 Soft"
                                 src="@/assets/img/logo-pb.png"
                            >
                        </router-link>
                    </a>
                </div>

                <div class="sidebar-content" style="height: 100%;">
                    <menu-component v-if="!companyId"/>
                    <menu-component v-if="companyId" :companyId="companyId"/>
                </div>
            </nav>

            <div class="page-content">

                <header class="header">
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                        <div class="toggle-btns">
                            <a id="toggle-sidebar" style="border-radius: 5px;" href="#"
                               @click.stop.prevent="toggleSidebar()">
                                <i class="icon-list"/>
                            </a>
                            <a id="pin-sidebar" style="border-radius: 5px;" href="#" @click.stop.prevent="pinSidebar()">
                                <i class="icon-list"/>
                            </a>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-3 col-md-2 col-sm-2 col-2"></div>
                    <!--            Essa div serve para o botão de toggle não sobrepror o profile em alguns aparelhos-->
                    <div v-if="!toggle" class="col-xl-4 col-lg-3 col-md-2 col-sm-2 col-2"></div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5" v-if="!isCompany && companies[0] && toggle">
                        <Select2 :settings="{'width': '100%'}" v-model="companySelected" :options="companies"
                                 :valueToSet="companyId" @select="mySelectEvent($event)"/>
                    </div>

                    <div class="col-xl-2 col-lg-4 col-md-5 col-sm-5 col-3">
                        <ul class="header-actions right-content">
                            <profile :isSecurity="havePassword" :user="profile" @logout="logout"
                                     @update="setProfileModal()"/>
                        </ul>
                    </div>
                </header>

                <div class="main-container">
                    <router-view/>
                    <modal-company-component v-if="showModal" :companies="companies"
                                             :companiesBackup="companiesBackup"/>
                    <input type="hidden" name="triger" id="profileTrigger" data-target="#modalProfile"
                           data-toggle="modal">
                    <div class="modal fade" id="modalProfile" data-backdrop="static" role="dialog"
                         aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="footerCenterBtnsModalLabel">
                                        <slot name="title">{{ t('APP.PROFILE') }}</slot>
                                    </h5>
                                    <button type="button" class="closeX" id="closeProfile" data-dismiss="modal"
                                            aria-label="Close"
                                            @click="closeFirstModal()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <profile-modal v-if="openProfile" :profileIndex="profile"
                                               @update="selectCompany()"></profile-modal>
                            </div>
                        </div>
                    </div>

                    <input type="hidden" name="imageCropper" id="imageCropper" data-target="#modalCropper"
                           data-toggle="modal">
                    <cropper-img @cropperUpdate="isCropping = false"/>

                    <input type="hidden" name="triger" id="profileTrigger" data-target="#modalPassword"
                           data-toggle="modal">
                    <div class="modal fade" id="modalPassword" data-backdrop="static" role="dialog"
                         aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="footerCenterBtnsModalLabel">
                                        <slot name="title">{{ t('APP.PROFILE') }}</slot>
                                    </h5>
                                    <button type="button" class="closeX" id="closeProfile" data-dismiss="modal"
                                            aria-label="Close"
                                            @click="closeFirstModal()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <password-modal v-if="openProfile" :profileIndex="profile"
                                                @update="selectCompany"></password-modal>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {useI18n} from 'vue-i18n';
import User from '@/services/user';
import {defineComponent} from 'vue';
import jwt_decode from 'jwt-decode';
import {mapActions, mapGetters, mapState} from 'vuex';
import {useToast} from 'vue-toastification';
import Select2 from 'vue3-select2-component';
import 'vue-advanced-cropper/dist/style.css';
import {event} from 'vue-gtag';
import Profile from '@/components/layouts/profile';
import CropperImg from '@/components/layouts/cropper';
import MenuComponent from '@/components/layouts/menuComponent';
import ModalCompanyComponent from '@/components/layouts/modalCompanyComponent';
import LoadingScreen from '@/components/layouts/loadScreenComponent.vue';
import profileModal from '@/components/layouts/profileModalComponent.vue';
import passwordModal from '@/components/layouts/changePasswordComponent.vue';

export default defineComponent({
    name: 'AppLayout',
    components: {
        Profile,
        Select2,
        CropperImg,
        profileModal,
        passwordModal,
        LoadingScreen,
        MenuComponent,
        ModalCompanyComponent,
    },
    setup() {
        const login = () => {
            event('login', {method: 'Google'});
        };
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast, login};
    },
    data() {
        return {
            profile: null,
            companiesAux: [],
            toggle: true,
            isCropping: false,
            beforeFive: false,
            havePassword: true,
            openProfile: false,
            openPassword: false,
            companyModal: null,
            companiesBackup: [],
            companySelected: '',
            companyNotLoad: false,
            showSelect2: this.$route.name != 'Company',
            companyId: localStorage.getItem('companyId'),
        };
    },
    computed: {
        ...mapState({
            image: 'image',
            pinned: 'pinned',
            toggled: 'toggled',
            isLoading: 'isLoading',
            showModal: 'showModal',
            companies: 'companies',
            isCompany: 'isCompany',
            oneToExpire: 'oneToExpire',
            fiveToExpire: 'fiveToExpire',
            closeAlertRefresh: 'closeAlertRefresh',
            showReahtorizationAlert: 'showReahtorizationAlert',
        }),
        ...mapGetters(['sessionStatus']),
    },
    watch: {
        showReahtorizationAlert: function () {
            if (this.showReahtorizationAlert == 5 || this.showReahtorizationAlert == 1) {
                this.showAlert();
                var date = new Date();
                var currentTime = new Date(date).getTime() / 1000.0;
                this.beforeFive = currentTime > this.fiveToExpire && currentTime < this.oneToExpire;
            } else if (!this.showReahtorizationAlert) {
                this.$swal.close();
            }
        },
        toggled: function () {
            this.toggle = !(this.toggled);
        },
    },
    mounted() {
        this.sessionStatus;
        this.startTime();
        this.indexUser(jwt_decode(localStorage.getItem('access_token')).user_id);
        this.startCompany();
        this.createGoogletagmanager();
        this.getHasConfigClickSign();
    },
    created() {
        this.$store.commit('startCompaniesToken');
        this.companiesBackup = this.companies;
    },
    methods: {
        ...mapActions({
            getHasConfigClickSign: 'getHasConfigClickSign'
        }),
        createGoogletagmanager() {
            setTimeout(() => {
                let token = jwt_decode(localStorage.getItem('access_token'));
                if (token && process.env.NODE_ENV === 'production') {
                    let body = document.getElementsByTagName('body')[0];
                    let script = document.createElement('script');
                    script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-EX1N4ML033');
                    script.async = true;
                    let userName = token.name;
                    let companyId = localStorage.getItem('companyId');
                    let companyName = '';
                    if (this.companies?.length && companyId) {
                        this.companies.forEach(item => {
                            if (item.id === companyId) {
                                companyName = item.text;
                            }
                        });
                    }
                    body.appendChild(script);
                    window.dataLayer = window.dataLayer || [];
                    this.gtag('js', new Date());
                    if (companyName.length > 15) {
                        companyName = companyName.substring(0, 15);
                    }
                    this.gtag('config', 'G-EX1N4ML033', {
                        'page_title': companyName + ' - ' + userName,
                    });
                    this.gtag('event', 'screen_view', {
                        'app_name': 'R2 Disponibilidade',
                        'screen_name': userName,
                    });
                }
            }, 4000);
        },
        gtag() {
            window.dataLayer.push(arguments);
        },
        logout() {
            this.$store.dispatch('ActionSingOut');
            this.$router.push({
                name: 'Login',
            });
        },
        mySelectEvent({id}) {
            this.companyId = id;
            localStorage.removeItem('companyId');
            localStorage.setItem('companyId', id);
            localStorage.removeItem('allotmentId');
            this.$router.go();
        },
        startCompany() {
            document.body.classList.remove('authentication');
            this.companySelected = localStorage.getItem('companyId');
        },
        startTime() {
            var date = new Date();
            var currentTime = new Date(date).getTime() / 1000.0;
            this.beforeFive = currentTime > this.fiveToExpire && currentTime < this.oneToExpire;
            if (currentTime > this.expirationTime) {
                this.$swal.close();
            }
        },
        selectCompany() {
            const companyId = localStorage.getItem('companyId');
            if (this.companies.length == 1) {
                this.$store.commit('showModalSelectCompany', false);
                this.companyId = this.companies[0].id;
                this.companySelected = this.companies[0].id;
                localStorage.setItem('companyId', this.companies[0].id);
            } else if (!companyId && this.companies[0]) {
                this.$store.commit('showModalSelectCompany', true);
            }
        },
        showAlert() {
            this.$swal.fire({
                title: this.t('GENERAL.MSG.SOME_TIME_TO_END_SESSION', {time: this.beforeFive ? '1' : '5'}),
                showCloseButton: true,
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonText: `Renovar`,
                cancelButtonText: 'Cancelar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.$store.dispatch('ActionRefreshToken');
                    return;
                }
                if (this.beforeFive) {
                    this.$store.dispatch('ActionSingOut');
                    this.$router.push({name: 'Login'});
                }
            }).catch(() => {
                this.$store.dispatch('ActionSingOut');
                this.$router.push({name: 'Login'});
            });
        },
        pinSidebar() {
            if (this.pinned) {
                document.getElementById('wrapper').classList.remove('pinned');
            } else {
                document.getElementById('wrapper').classList.add('pinned');
                document.getElementById('configSubmenu').classList.remove('show');
            }
            this.$store.commit('pinSidebar');
        },
        toggleSidebar() {
            if (this.toggled) {
                document.getElementById('wrapper').classList.remove('toggled');
            } else {
                document.getElementById('wrapper').classList.add('toggled');
                document.getElementById('configSubmenu').classList.remove('show');
            }
            this.$store.commit('toggleSidebar');
        },
        async setProfileModal() {
            this.$store.commit('changeLoading', true);
            this.$store.commit('setImageCropped', null);
            let userId = jwt_decode(localStorage.getItem('access_token')).user_id;
            await User.show(userId).then(resp => {
                this.profile = resp.data;
                this.openProfile = true;
                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error)
            });
        },
        async indexUser(id) {
            await User.show(id).then(resp => {
                if (resp.data.havePassword === false) {
                    this.havePassword = false;
                }
                this.profile = resp.data;
                if (!resp.data.phones[0]) {
                    if (localStorage.getItem('firstProfileClosed') == null || localStorage.getItem('firstProfileClosed') == 'true') {
                        localStorage.setItem('firstProfileClosed', true);
                        document.getElementById('profileTrigger').click();
                        this.openProfile = true;
                    }
                } else {
                    this.selectCompany();
                }
            }).catch(error => this.errorMsg(error));
        },
        closeFirstModal() {
            if (localStorage.getItem('firstProfileClosed') == 'true') {
                localStorage.setItem('firstProfileClosed', false);
            }
        },
    },
});

</script>
<style lang="less">
.size {
    width: 200px !important;
}
.size-modal {
    width: 100% !important;
}
.btn-background {
    width: 100%;
    font-size: large !important;
    font-weight: 600 !important;
    background-color: #adadad !important;
}
.modal-dialog {
    overflow-y: initial !important
}
.modal-row2 {
    max-height: 40vh;
    overflow-y: auto;
}
.modal-row {
    max-height: 40vh;
    overflow-y: auto;
}
.modal-row2::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    background: transparent !important;
}
.modal-row2::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-header-color {
    color: white;
    background-color: #0e5e4e !important;
}
.right-content {
    justify-content: flex-end;
}
.swal2-container {
    z-index: 100000000000;
}
.user-avatar {
    max-width: 80px !important;
    max-height: 80px !important;
    border-radius: 50% !important;
    margin-bottom: 1.2rem !important;
}
.avatar-size {
    width: 80px !important;
    height: 80px !important;
}
.cropper-size {
    max-width: 350px !important;
    max-height: 275px !important;
}
.avatar .status {
    top: 62px;
    right: 2px;
    width: 25px;
    height: 25px;
    font-size: 18px;
    position: absolute;
    border-radius: 15px;
    border: 4px solid #f0f0f0;
}
.camera {
    right: 1;
    bottom: 0;
    outline: 0;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    border-radius: 15px;
    background-color: #f0f0f0;
}
.align-items {
    align-items: center;
}
.preview {
    border: dashed 1px rgba(255, 255, 255, 0.25);
}
.cropper {
    height: 100%;
    background: #DDD;
}
.hearder-custom {
    color: #333333 !important;
    background: #ffffff !important;
}
.close-custom {
    color: #000 !important;
}
.border-bottom {
    border-bottom: 1px solid #e6ebf1;
}
.border-top {
    background-color: #f2f4f9;
    border-top: 1px solid #e6ebf1;
}
.lg {
    max-width: 800px !important;
}
</style>
